import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import { Formik } from "formik"
import * as Yup from "yup"
import * as _ from "lodash"
import Box from "@components/styled/Box"
import Container from "@components/styled/Container"
import Layout from "@components/layout"
import RadioGroup from "@components/RadioGroup"
import SEO from "@components/seo"
import TextInput from "@components/TextInput"
import SelectInput from "@components/Select"
import moment from "moment"
import { SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"
import get from "lodash/get"
import {
  base,
  fetchEducationSystemPartners,
  fetchEvaluationPartners,
  fetchPhilanthropyPartners
} from "@helpers/airtable"
import {
  FormHead,
  Form,
  FormSection,
  FieldGroup,
  FormActions,
  Textarea
} from "@components/Form"
import { authCheckRedirect } from "@helpers/auth"
import { mapOptions } from "@helpers/data"
import Back from "@components/Back"

const initialFormState = {
  project_name: "",
  project_type: "",
  education_partner: "",
  evaluation_partner: "",
  philanthropy_partner: "",
  partner: "",
  state_territory: "",
  sector: "",
  education_system_investment: 0,
  philanthropy_investment: 0,
  schools_involved: 0,
  students_involved: 0,
  potential_reach: 0,
  stage: "",
  identifiedDate: moment(),
  interestDate: moment(),
  developmentDate: moment(),
  liveDate: moment(),
  URL: "",
  comments: ""
}

const validationSchema = Yup.object().shape({
  project_name: Yup.string().required("This is required"),
  project_type: Yup.string().required("This is required"),
  stage: Yup.string().required("This is required"),
  state_territory: Yup.string().required("This is required"),
  sector: Yup.string().required("This is required")
})

const AddProjectPage = ({ user, data, ...props }) => {
  const [loadingPartnerData, setLoadingPartnerData] = useState(false)
  const [partnerOptionsData, setPartnerOptionsData] = useState({
    educationPartners: [],
    evaluationPartners: [],
    philanthropyPartners: []
  })
  const [submissionError, setSubmissionError] = useState(false)

  useEffect(() => {
    authCheckRedirect()
    const fetchData = async () => {
      function mapDataAsOptions(arr, type = "") {
        return arr
          .map(record => ({
            id: record.id,
            name: record.fields.Name
          }))
          .map(record => ({
            value: record.id,
            label: record.name,
            type
          }))
      }

      let data = await Promise.all([
        fetchEducationSystemPartners(),
        fetchEvaluationPartners(),
        fetchPhilanthropyPartners()
      ])

      setPartnerOptionsData({
        educationPartners: mapDataAsOptions(data[0], "partner"),
        evaluationPartners: mapDataAsOptions(data[1], "partner"),
        philanthropyPartners: mapDataAsOptions(data[2], "partner")
      })

      setLoadingPartnerData(false)
    }

    const {
      educationPartners,
      evaluationPartners,
      philanthropyPartners
    } = partnerOptionsData
    if (
      !educationPartners.length &&
      !evaluationPartners.length &&
      !philanthropyPartners.length
    ) {
      setLoadingPartnerData(true)
      fetchData()
    }
  }, [])

  const [calendarFocus, setCalendarFocus] = useState({
    identified: false,
    interest: false,
    development: false,
    live: false
  })

  const stageOrder = ["Identified", "Interest", "Development", "Live"]

  let stageOptions = mapOptions(data.stageOptions.edges)

  stageOptions = _.sortBy(stageOptions, function(obj) {
    return _.indexOf(stageOrder, obj.label)
  })

  const projectTypeOptions = mapOptions(
    data.projectTypeOptions.edges,
    "project_type"
  )

  const stateTerritoryOptions = mapOptions(
    data.stateTerritoryOptions.edges,
    "state_territory"
  )
  const sectorOptions = mapOptions(data.sectorOptions.edges, "sector")

  const onCalendarFocusChange = (e, type) => {
    setCalendarFocus({ ...calendarFocus, [type]: e.focused })
  }

  const createProject = async values => {
    const project = {
      fields: {
        "Project Name": values.project_name,
        "Project Type": [values.project_type],

        "State / Territory": [values.state_territory],
        Sector: [values.sector],
        "Current stage": [values.stage],
        "Education System Partner/s": values.education_partner
          ? [values.education_partner]
          : [],
        "Evaluator Partner/s": values.evaluation_partner
          ? [values.evaluation_partner]
          : [],
        "Philanthropy Partner/s": values.philanthropy_partner
          ? [values.philanthropy_partner]
          : [],
        Identified: values.identifiedDate.format("YYYY-MM-DD"),
        Interest: values.interestDate.format("YYYY-MM-DD"),
        Development: values.developmentDate.format("YYYY-MM-DD"),
        Live: values.liveDate.format("YYYY-MM-DD"),
        "Education System Investment": values.education_system_investment
          ? values.education_system_investment
          : 0,
        "Philanthropy Investment": values.philanthropy_investment
          ? values.philanthropy_investment
          : 0,
        "Schools Involved": values.schools_involved,
        "Potential Reach": values.potential_reach,
        "Students Involved": values.students_involved,
        URL: values.URL,
        Comments: values.comments
      }
    }

    return base("Projects").create([project])
  }

  const handleSubmit = async (values, actions) => {
    try {
      await createProject(values)
      actions.setSubmitting(false)
      navigate("/view-projects")
    } catch (e) {
      setSubmissionError(true)
      actions.setSubmitting(false)
      console.log(e, "error")
    }
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="Add project" />
      <Container pt={[7]}>
        <section>
          <Back to="/view-projects" />
          <FormHead title="Add a project" />
          <Formik
            initialValues={initialFormState}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              handleBlur,
              submitForm,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              resetForm,
              values,
              errors,
              touched
            }) => (
              <Form>
                <FormSection title="Basics">
                  <Columns>
                    <Column>
                      <FieldGroup
                        required
                        name="project_name"
                        label="Project name"
                      >
                        <TextInput
                          name="project_name"
                          value={values.project_name}
                          onChange={handleChange}
                          error={
                            get(touched, "project_name") &&
                            get(errors, "project_name") &&
                            get(errors, "project_name")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>

                    <Column width={[1, 0.5]}>
                      <FieldGroup
                        required
                        name="project_type"
                        label="Project type"
                      >
                        <SelectInput
                          name="project_type"
                          value={values.project_type}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={
                            get(touched, "project_type") &&
                            get(errors, "project_type") &&
                            get(errors, "project_type")
                          }
                          options={projectTypeOptions}
                        />
                      </FieldGroup>
                    </Column>

                    <Column width={[1, 0.5]}>
                      <FieldGroup
                        required
                        name="state_territory"
                        label="State/Territory"
                      >
                        <SelectInput
                          name="state_territory"
                          value={values.state_territory}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={
                            get(touched, "state_territory") &&
                            get(errors, "state_territory") &&
                            get(errors, "state_territory")
                          }
                          options={stateTerritoryOptions}
                        />
                      </FieldGroup>
                    </Column>
                    <Column width={[1, 0.5]}>
                      <FieldGroup required name="sector" label="Sector">
                        <SelectInput
                          name="sector"
                          value={values.sector}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={
                            get(touched, "sector") &&
                            get(errors, "sector") &&
                            get(errors, "sector")
                          }
                          options={sectorOptions}
                        />
                      </FieldGroup>
                    </Column>
                  </Columns>
                </FormSection>

                <FormSection title="Status">
                  <Columns>
                    <Column>
                      <FieldGroup required name="stage" label="Stage">
                        <RadioGroup
                          name="stage"
                          values={values}
                          options={stageOptions}
                          onChange={handleChange}
                          error={
                            get(touched, "stage") &&
                            get(errors, "stage") &&
                            get(errors, "stage")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>
                    <Column>
                      <Columns>
                        <Column width={0.25}>
                          <FieldGroup
                            name="identifiedDate_input"
                            label="Identified date"
                          >
                            <SingleDatePicker
                              id="identifiedDate_input"
                              date={values.identifiedDate}
                              focused={calendarFocus.identified}
                              onDateChange={e =>
                                setFieldValue("identifiedDate", e)
                              }
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "identified")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                        <Column width={0.25}>
                          <FieldGroup
                            name="interestDate_input"
                            label="Interest date"
                          >
                            <SingleDatePicker
                              id="interestDate_input"
                              date={values.interestDate}
                              focused={calendarFocus.interest}
                              onDateChange={e =>
                                setFieldValue("interestDate", e)
                              }
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "interest")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                        <Column>
                          <FieldGroup
                            name="developmentDate_input"
                            label="Development date"
                          >
                            <SingleDatePicker
                              id="developmentDate_input"
                              date={values.developmentDate}
                              focused={calendarFocus.development}
                              onDateChange={e =>
                                setFieldValue("developmentDate", e)
                              }
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "development")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                        <Column>
                          <FieldGroup name="liveDate_input" label="Live date">
                            <SingleDatePicker
                              id="liveDate_input"
                              date={values.liveDate}
                              focused={calendarFocus.live}
                              onDateChange={e => setFieldValue("liveDate", e)}
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "live")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                      </Columns>
                    </Column>
                  </Columns>
                </FormSection>

                <FormSection title="Partner">
                  {loadingPartnerData ? (
                    <Box>Loading...</Box>
                  ) : (
                    <>
                      <Columns>
                        <Column width={[1, 4 / 12]}>
                          <FieldGroup
                            name="education_partner"
                            label="Education partner"
                          >
                            <SelectInput
                              name="education_partner"
                              value={values.education_partner}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              options={partnerOptionsData.educationPartners}
                              placeholder="Select an education partner"
                            />
                          </FieldGroup>
                        </Column>
                        <Column>
                          <FieldGroup
                            name="education_system_investment"
                            label="Investment from Education partner"
                          >
                            <TextInput
                              type="number"
                              name="education_system_investment"
                              value={values.education_system_investment}
                              onChange={handleChange}
                            />
                          </FieldGroup>
                        </Column>
                      </Columns>

                      <Columns>
                        <Column width={[1, 4 / 12]}>
                          <FieldGroup
                            name="evaluation_partner"
                            label="Evaluation partner"
                          >
                            <SelectInput
                              name="evaluation_partner"
                              value={values.evaluation_partner}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              options={partnerOptionsData.evaluationPartners}
                              placeholder="Select an evaluation partner"
                            />
                          </FieldGroup>
                        </Column>
                        <Column></Column>
                      </Columns>
                      <Columns>
                        <Column width={[1, 4 / 12]}>
                          <FieldGroup
                            name="philanthropy_partner"
                            label="Partner"
                          >
                            <SelectInput
                              name="philanthropy_partner"
                              value={values.philanthropy_partner}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              options={partnerOptionsData.philanthropyPartners}
                              placeholder="Select a philanthropy partner"
                            />
                          </FieldGroup>
                        </Column>
                        <Column>
                          <FieldGroup
                            name="philanthropy_investment"
                            label="Investment from Philanthropy partner"
                          >
                            <TextInput
                              type="number"
                              name="philanthropy_investment"
                              value={values.philanthropy_investment}
                              onChange={handleChange}
                            />
                          </FieldGroup>
                        </Column>
                      </Columns>
                    </>
                  )}
                </FormSection>
                <FormSection title="Stats">
                  <Columns>
                    <Column>
                      <FieldGroup
                        name="schools_involved"
                        label="Schools involved"
                      >
                        <TextInput
                          type="number"
                          name="schools_involved"
                          value={values.schools_involved}
                          onChange={handleChange}
                        />
                      </FieldGroup>
                    </Column>
                    <Column>
                      <FieldGroup
                        name="potential_reach"
                        label="Potential reach"
                      >
                        <TextInput
                          type="number"
                          name="potential_reach"
                          value={values.potential_reach}
                          onChange={handleChange}
                        />
                      </FieldGroup>
                    </Column>
                    <Column>
                      <FieldGroup
                        name="students_involved"
                        label="Students involved"
                      >
                        <TextInput
                          type="number"
                          name="students_involved"
                          value={values.students_involved}
                          onChange={handleChange}
                        />
                      </FieldGroup>
                    </Column>
                  </Columns>
                </FormSection>
                <FormSection>
                  <FieldGroup name="comments" label="Comments">
                    <Textarea
                      name="comments"
                      id="comments"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldGroup>

                  <FieldGroup name="URL" label="URL">
                    <TextInput
                      name="URL"
                      value={values.URL}
                      onChange={handleChange}
                    />
                  </FieldGroup>
                </FormSection>

                <FormActions
                  onSubmitClick={submitForm}
                  onClearClick={() => resetForm(initialFormState)}
                  submitDisabled={isSubmitting}
                  error={submissionError}
                />
              </Form>
            )}
          </Formik>
        </section>
      </Container>
    </Layout>
  )
}

export default AddProjectPage

export const query = graphql`
  query {
    projects: allAirtable(filter: { table: { eq: "Projects" } }) {
      edges {
        node {
          recordId
          data {
            Project_Name
            Potential_Reach
            Current_stage
            Live
            URL
            Identified
            Interest
            Development
            State___Territory {
              recordId
              data {
                Name
              }
            }
            Sector {
              recordId
              data {
                Name
              }
            }
            Project_Type {
              recordId
              data {
                Name
              }
            }
            Education_System_Partner_s {
              recordId
              data {
                Name
              }
            }
            Evaluator_Partner_s {
              recordId
              data {
                Name
              }
            }
            Philanthropy_Partner_s {
              recordId
              data {
                Name
              }
            }
            Comments
            Philanthropy_Investment
            Education_System_Investment
            Schools_Involved
            Students_Involved
          }
        }
      }
    }

    stateTerritoryOptions: allAirtable(
      filter: { table: { eq: "DL_State_Territory" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    sectorOptions: allAirtable(filter: { table: { eq: "DL_SECTOR" } }) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    projectTypeOptions: allAirtable(
      filter: { table: { eq: "DL_Project_Type" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    stageOptions: allAirtable(
      filter: { table: { eq: "DL_Product_Development_Stage" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
  }
`
